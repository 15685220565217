import { fromJwt, TokenService, Tokens, subscribeToPeerTabUpdates, RefreshCallback, LocalStorageCompatible } from '@weareyipyip/multitab-token-refresh'
import ApiClient from '@services/unprotectedApiClient'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { hasValue } from '@misc/helpers'

/// /////////
// Storage //
/// /////////

// all of this crap is necessary to dupe nextjs into running in non-bitching mode
const inMemoryStorage = new Map()
const dummyLocalStorage: LocalStorageCompatible = {
  getItem (key) { return inMemoryStorage.get(key) },
  setItem (key, value) { inMemoryStorage.set(key, value) },
  removeItem (key) { inMemoryStorage.delete(key) }
}

const storage = typeof window !== 'undefined' ? localStorage : dummyLocalStorage

/// ////////////////
// Token handling //
/// ////////////////

const tokenKey = 'apiToken'

function storeToken (token: string): void {
  storage.setItem(tokenKey, token)
}

function getToken (): string {
  return storage.getItem(tokenKey) ?? ''
}

function deleteToken (): void {
  storage.removeItem(tokenKey)
}

function axiosAuthRequestInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  const apiToken = getToken()
  if (apiToken !== '' && hasValue(config.headers)) {
    config.headers.Authorization = 'Bearer ' + apiToken
  }
  return config
}

/// /////////////////////////////////
// Token handling (for future use) //
/// /////////////////////////////////

function authResponseToTokens (resp: any): Tokens {
  const { data: { data: { access, refresh } } } = resp
  const tokens = fromJwt({ accessToken: access, refreshToken: refresh })
  return tokens
}

const refreshCurrentSession: RefreshCallback = async (refreshToken, setLoggedOut) => {
  return await ApiClient.post('/me/current_session/refresh', null, {
    headers: { authorization: `Bearer ${refreshToken}` }
  })
    .then(authResponseToTokens)
    .catch((err: AxiosError) => {
      if (err.response?.status === 401) setLoggedOut()
      throw err
    })
}

// const axiosAuthRequestInterceptor = createAxiosAuthRequestInterceptor(tokenService) // for future use

/// //////////////
// Service init //
/// //////////////

const tokenService = new TokenService(refreshCurrentSession, { storage })
subscribeToPeerTabUpdates(tokenService)

export { tokenService, storeToken, getToken, deleteToken, authResponseToTokens, refreshCurrentSession, axiosAuthRequestInterceptor }
