import { hasValue } from '@misc/helpers'
import clsx from 'clsx'
import React from 'react'
import style from './styles.module.css'
import { ReactComponent as EyeOpen } from '@icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '@icons/eye-closed.svg'

interface InputProps {
  disabled?: boolean
  hasError?: boolean
  ref?: React.RefObject<HTMLInputElement>
}

type Props = InputProps & React.InputHTMLAttributes<HTMLInputElement>

export const TextInput = (props: Props): JSX.Element => {
  const classes = clsx(
    props.className,
    style.input,
    hasValue(props.hasError) && props.hasError && style['input-has-error']
  )
  const { type, className, ref, hasError, ...inputProps } = props

  const [passwordShown, setPasswordShown] = React.useState<boolean>(false)

  const clickHandler = (): void => {
    setPasswordShown(o => !o)
  }

  return (
    <div className={style['input-wrapper']}>
      <input ref={ref} type={hasValue(passwordShown) && passwordShown ? 'text' : type} className={classes} {...inputProps} />
      {hasValue(type) && type === 'password' && (
        <div className={style['icon-wrapper']}>
          <button type='button' onClick={clickHandler}>
            {passwordShown ? <EyeClosed /> : <EyeOpen />}
          </button>
        </div>
      )}
    </div>
  )
}
