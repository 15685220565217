import { Button } from '@root/components/atoms/Button/Component'
import style from './style.module.css'
import { ReactComponent as Refresh } from '@icons/refresh.svg'
import clsx from 'clsx'
import { ReactComponent as Check } from '@icons/check.svg'
import { useEffect, useState, useRef } from 'react'
import apiService from '@root/services/apiService'
import { Trial } from '@root/services/types'
import { ReactComponent as Spinner } from '@icons/spinner.svg'
import { motion } from 'framer-motion'
import { hasValue } from '@root/misc/helpers'

export const OverviewPage = (): JSX.Element => {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [fetched, setFetched] = useState<boolean>(false)
  const [trials, setTrials] = useState<Trial[] | null>(null)
  const [cardHeight, setCardHeight] = useState<number>(0)

  const cardRef = useRef<HTMLDivElement>(null)

  const onAnimationCompleteHandler = (): void => {
    setCardHeight(cardRef.current?.getBoundingClientRect().height ?? 0)
  }

  const fetchData = (): void => {
    setError(false)
    setLoading(true)
    apiService.client.trials()
      .then((response) => {
        setFetched(true)
        setTrials(response.data.data)
        setTimeout(() => setLoading(false), 350)
      })
      .catch(() => {
        setError(true)
        setFetched(true)
        setTimeout(() => setLoading(false), 350)
      })
  }

  useEffect(() => fetchData(), [])

  return (
    <>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <h1>Oefeningen</h1>
        <Button type='button' buttonType='secondary' color='purple' as='button' onClick={() => fetchData()}><Refresh /> <span>Ververs lijst</span></Button>
      </div>
      <div className={style.data}>
        <motion.div
          ref={cardRef}
          initial={{ height: 200 }}
          animate={{ height: loading ? [cardHeight, 200] : 'auto' }}
          transition={{ duration: 0.35, delay: 0 }}
          onAnimationComplete={onAnimationCompleteHandler}
        >
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: loading ? [0, 0.99] : [0, 1] }} transition={{ duration: loading ? 0 : 0.15, delay: loading ? 0 : 0.15 }}>
            {
              (hasValue(error) && error) && (
                <>
                  <div className={clsx(style['flex-bar'], style['title-bar'], style['title-bar-no-data'])}>
                    <h1>Er zijn op dit moment geen oefeningen beschikbaar</h1>
                  </div>

                  <div className={style.data}>
                    <div className={style['data-no-data']}>
                      <p>Er is iets mis gegaan bij het ophalen van de oefeningen, probeer het nogmaals.</p>
                    </div>
                    <div className={clsx(style['flex-bar'], style['check-bar'])}>
                      <Button type='button' buttonType='secondary' color='purple' as='button' onClick={() => fetchData()}><Refresh /> <span>Opnieuw controleren</span></Button>
                    </div>
                  </div>

                </>
              )
            }

            {
              loading && (<div className={style.loading}><div className='spinner'><Spinner /></div></div>)
            }
            {
              !loading && (
                <>
                  {
                    hasValue(fetched) && fetched && (!hasValue(trials) || (trials.length === 0)) && (
                      <>
                        <div className={clsx(style['flex-bar'], style['title-bar'], style['title-bar-no-data'])}>
                          <h1>Er zijn op dit moment geen oefeningen beschikbaar</h1>
                        </div>

                        <div className={style.data}>
                          <div className={style['data-no-data']}>
                            <p>U krijgt een bericht van uw logopedist wanneer er nieuwe oefeningen zijn klaargezet</p>
                          </div>
                        </div>

                        <div className={clsx(style['flex-bar'], style['check-bar'])}>
                          <Button type='button' buttonType='secondary' color='purple' as='button' onClick={() => fetchData()}><Refresh /> <span>Opnieuw controleren</span></Button>
                        </div>
                      </>
                    )
                  }

                  {
                    hasValue(fetched) && fetched && hasValue(trials) && trials.length > 0 && (
                      <>
                        <div className={style['data-new']}>
                          {trials.filter(a => a.completed_at === 'null').map((d, i) => {
                            return (
                              <div className={clsx(style['flex-bar'], style.assignment)} key={`opdracht_${i}`}>
                                <div>
                                  <h2>{d.title}</h2>
                                  <p>
                                    {new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.parse(d.available_at))}
                                  </p>
                                </div>
                                <Button type='button' buttonType='primary' as='link' href={`/opdracht/${d.id}`}>Start oefening</Button>
                              </div>
                            )
                          })}
                        </div>
                        <div className={style['data-done']}>
                          {trials.filter(a => a.completed_at !== 'null').map((d, i) => {
                            return (
                              <div className={clsx(style['flex-bar'], style.assignment)} key={`opdracht_${i}`}>
                                <div>
                                  <h2>{d.title}</h2>
                                  <p>
                                    {new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.parse(d.available_at))}
                                  </p>
                                </div>
                                <div className={style.icon}><Check /></div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    )
}
                </>
              )
}
          </motion.div>
        </motion.div>
      </div>
    </>
  )
}
