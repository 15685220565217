import { getApiUrl } from '@misc/environments'
import Axios from 'axios'
import AxiosUtils from '@services/axiosUtils'

const ApiClient = Axios.create({
  baseURL: getApiUrl(),
  headers: { 'content-type': 'application/json' }
})
ApiClient.interceptors.request.use(AxiosUtils.csrfHeaderRequestInterceptor)
ApiClient.interceptors.request.use(AxiosUtils.appVersionHeaderRequestInterceptor)
ApiClient.interceptors.response.use((res) => res, AxiosUtils.logErrorInterceptor)

export default ApiClient
