import { hasValue } from '@misc/helpers'
import { AxiosError, AxiosRequestConfig } from 'axios'

/**
 * Axios response error interceptor to log API errors to the console.
 */
function logErrorInterceptor (error: AxiosError): void {
  if (hasValue(error.response)) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error.response.data))
  }
  // rethrow the error so that the contract of Axios functions stays the same and the error is visible to the caller
  throw error
}

/**
 * Axios request interceptor which sets x-csrf-token header to any value.
 *
 * @param {Object} config
 */
function csrfHeaderRequestInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  const { method } = config
  if (hasValue(method) && hasValue(config.headers) && ['post', 'put', 'delete', 'patch'].includes(method)) {
    config.headers['x-csrf-token'] = 'anything'
  }
  return config
}

/**
 * Axios request interceptor which sets header `yy-app-version` to `afasietherapie x.x.x`
 */
function appVersionHeaderRequestInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  if (hasValue(config.headers)) {
    config.headers['yy-app-version'] = 'afasietherapie 0.0.0+development'
  }
  return config
}

/**
 * Axios request interceptor which sets global loading state to true.
 *
 * @param {Object} config
 */
function startLoadingRequestInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  // loadingState.loading = true
  return config
}

/**
 * Axios success response interceptor which sets global loading state to false.
 *
 * @param {Object} config
 */
function stopLoadingResponseInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  // loadingState.loading = false
  return config
}

/**
 * Axios error response interceptor which sets global loading state to false.
 *
 * @param {Object} error
 */
function stopLoadingErrorInterceptor (error: AxiosError): AxiosError {
  // loadingState.loading = false
  throw error
}

/**
 * Axios error response interceptor which scans for a 401 response status
 *
 * @param {Object} error
 */
function noAuthInterceptor (error: AxiosError): void {
  if (error.response?.status === 401) {
    window.location.replace('/')
  }
  throw error
}

const exports = {
  logErrorInterceptor,
  csrfHeaderRequestInterceptor,
  startLoadingRequestInterceptor,
  stopLoadingResponseInterceptor,
  stopLoadingErrorInterceptor,
  appVersionHeaderRequestInterceptor,
  noAuthInterceptor
}

export default exports
