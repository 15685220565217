import styles from './style.module.css'
import { ReactComponent as AudioLogo } from '@icons/audio.svg'
import { ReactComponent as AudioPlayingLogo } from '@icons/audio-playing.svg'
import { ReactComponent as AudioSpinner } from '@icons/audio-spinner.svg'
import { useAppContext } from '@root/global/context'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { hasValue } from '@root/misc/helpers'

interface Props {
  audioSrcs: string[]
  btnSize: 'small' | 'medium' | 'large'
  isHint?: boolean
  setActiveFn?: (audioIndex: number | null) => void
  isInvisible?: boolean
}

export const AudioBtn = (props: Props): JSX.Element => {
  const [audioElement] = useState<HTMLAudioElement | undefined>(new Audio())
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number>(0)
  const [audioState, setAudioState] = useState<number>(0)
  const { setErrorMsg } = useAppContext()
  const errorMsg = 'Door een probleem kon de audio niet worden afgespeeld.'
  const getBtnSize = props.btnSize === 'small' ? styles['btn-small'] : props.btnSize === 'medium' ? styles['btn-medium'] : styles['btn-large']

  const startAudio = (): void => {
    if (audioElement !== undefined) {
      setCurrentAudioIndex(0)
      if (props.audioSrcs.length > 0) {
        playNext()
      } else {
        setErrorMsg(errorMsg)
      }
    } else {
      setErrorMsg(errorMsg)
    }
  }

  const playNext = (): void => {
    if (currentAudioIndex < props.audioSrcs.length && audioElement !== undefined) {
      setAudioState(1)
      hasValue(props.setActiveFn) && props.setActiveFn(currentAudioIndex)
      audioElement.src = props.audioSrcs[currentAudioIndex]
      audioElement.onerror = function () {
        setErrorMsg(errorMsg)
        setAudioState(0)
      }
      audioElement.onloadeddata = function () {
        audioElement.play().then(() => setAudioState(2)).catch(() => {
          setErrorMsg(errorMsg)
          setAudioState(0)
        })
      }
      setCurrentAudioIndex(currentAudioIndex + 1)
    } else if (currentAudioIndex === props.audioSrcs.length) {
      setCurrentAudioIndex(0)
    }
  }

  if (audioElement !== undefined) {
    audioElement.onended = () => {
      setAudioState(0)
      hasValue(props.setActiveFn) && props.setActiveFn(null)
      playNext()
    }
  }

  useEffect(() => {
    if (hasValue(props.isInvisible) && props.isInvisible) {
      startAudio()
    }
  }, [props.isInvisible])

  return hasValue(props.isInvisible) && props.isInvisible
    ? (<></>)
    : (
      <button disabled={audioState !== 0} className={clsx(styles['audio-btn'], getBtnSize, hasValue(props.isHint) && props.isHint && styles.hint)} onClick={() => startAudio()}>
        {audioState === 0 ? (<AudioLogo />) : audioState === 1 ? (<div className={clsx('spinner', styles.spinner)}><AudioSpinner /></div>) : (<AudioPlayingLogo />)}
      </button>
      )
}
