import { CueState, TrialSession } from '@root/services/types'
import React from 'react'

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

interface IContext {
  errorMsg: string | null
  setErrorMsg: StateSetter<string | null>
  cues: CueState[] | null
  setCues: StateSetter<CueState[] | null>
  sessionContext: TrialSession | null
  setSessionContext: StateSetter<TrialSession | null>
  skip: boolean | null
  setSkip: StateSetter<boolean | null>
  isTransitioning: boolean
  setIsTransitioning: StateSetter<boolean>
}

export function useAppValue (): IContext {
  const [errorMsg, setErrorMsg] = React.useState<string | null>('')
  const [cues, setCues] = React.useState<CueState[] | null>(null)
  const [sessionContext, setSessionContext] = React.useState<TrialSession | null>(null)
  const [skip, setSkip] = React.useState<boolean | null>(null)
  const [isTransitioning, setIsTransitioning] = React.useState<boolean>(false)

  return {
    errorMsg,
    setErrorMsg,
    cues,
    setCues,
    sessionContext,
    setSessionContext,
    skip,
    setSkip,
    isTransitioning,
    setIsTransitioning
  }
}

export const appContext = React.createContext<IContext | null>(null)

export function useAppContext (): IContext {
  const c = React.useContext(appContext)
  if (c !== null) {
    return c
  }
  throw Error('No context available')
}
