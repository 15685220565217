import { useAppContext } from '@global/context'
import { hasValue } from '@misc/helpers'
import style from './style.module.css'
import clsx from 'clsx'
import { ErrorMessage } from '@root/components/atoms/Error/Component'
import useWindowSize from '@misc/use-window-dimensions'
import { ReactComponent as Logout } from '@icons/logout.svg'
import { ReactComponent as ChevronLeft } from '@icons/chevron-left.svg'
import { ReactComponent as Skip } from '@icons/skip.svg'
import React, { useEffect } from 'react'
import { Modal } from '@root/components/atoms/Modal/Component'
import { Button } from '@root/components/atoms/Button/Component'
import { useLocation, useNavigate } from 'react-router-dom'

import apiService from '@root/services/apiService'
import { deleteToken } from '@root/services/tokenService'
import { CueState, LoginResponse } from '@root/services/types'
import { AxiosResponse } from 'axios'
import { motion } from 'framer-motion'

interface Props {
  children: React.ReactNode
  layout: 'small' | 'medium' | 'large'
  noHeader?: boolean
  noPB?: boolean
  noPBnoPH?: boolean // no padding bottom no padding horizontal
  navigation?: 'back' | 'logout'
}

export const Layout = (props: Props): JSX.Element => {
  const { errorMsg, cues, setCues, sessionContext, setSessionContext, setSkip, isTransitioning } = useAppContext()
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false)
  const [showStopModal, setShowStopModal] = React.useState<boolean>(false)

  const { navigation = 'logout', ...restProps } = props
  const navigate = useNavigate()

  useWindowSize()

  const userRaw = localStorage.getItem('user')
  const user = hasValue(userRaw) && userRaw !== '' ? JSON.parse(userRaw) : null

  const clickHandler = (): void => {
    setShowLogoutModal(s => !s)
  }
  const stopHandler = (): void => {
    setShowStopModal(s => !s)
  }

  const logoutHandler = (): void => {
    apiService.auth.logout()
      .then((res: AxiosResponse<LoginResponse>) => {
        deleteToken()
        localStorage.setItem('user', '')
        navigate('/')
      })
      .catch(() => {
        deleteToken()
        localStorage.setItem('user', '')
        navigate('/')
      })
  }

  const location = useLocation()

  useEffect(() => {
    if (!location.pathname.startsWith('/opdracht')) {
      setCues([])
      setSessionContext(null)
    }
  }, [location])

  return (
    <>
      {showLogoutModal && (
        <Modal closeFn={() => setShowLogoutModal(false)}>
          <div className={style['modal-content']}>
            <h1>Weet u zeker dat u wilt uitloggen?</h1>
            <p>Als u uitlogt moet u opnieuw inloggen voor de volgende oefeningen, het is niet noodzakelijk om uit te loggen.</p>
            <div>
              <Button
                type='button'
                as='button'
                onClick={() => {
                  clickHandler()
                  logoutHandler()
                }}
                color='purple'
                buttonType='primary'
              >
                Uitloggen
              </Button>
              <Button type='button' as='button' onClick={clickHandler} color='purple' buttonType='bordered'>Annuleren</Button>
            </div>
          </div>
        </Modal>
      )}
      {showStopModal && (
        <Modal closeFn={() => setShowStopModal(false)}>
          <div className={style['modal-content']}>
            <h1>Weet u zeker dat u de oefening wilt stoppen?</h1>
            <p>Als u stopt worden de oefeningen die u al gedaan heeft niet bewaard. Uw logopedist kan dan niet zien hoe u de oefeningen gemaakt heeft. U kunt op elk moment opnieuw aan de oefeningen beginnen.</p>
            <div>
              <Button
                type='button'
                as='button'
                onClick={() => {
                  stopHandler()
                  navigate('/overzicht')
                }}
                color='purple'
                buttonType='primary'
              >
                Ja, stop de oefening
              </Button>
              <Button type='button' as='button' onClick={stopHandler} color='purple' buttonType='bordered'>Nee, ga verder</Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={style['page-wrapper']}>
        {(!hasValue(restProps.noHeader) || !restProps.noHeader) && (
          <header className={style.header}>
            {navigation === 'logout' && (<button className={style['nav-button']} onClick={clickHandler} type='button'><Logout /><span>Uitloggen</span></button>)}
            {navigation === 'back' && (<button className={style['nav-button']} onClick={stopHandler}><ChevronLeft /><span>Stop met oefening</span></button>)}
            {hasValue(user) && (<div className={style.user}>Ingelogd als {user.prefix} {user.first_name} {user.last_name}</div>)}
            {!hasValue(user) && (<div className={style.user}>Gebruiker</div>)}
          </header>
        )}

        {hasValue(errorMsg) && errorMsg !== '' && (
          <div className={style['error-wrapper']}>
            <ErrorMessage msg={errorMsg} />
          </div>
        )}

        <main className={
          clsx(
            style['main-wrapper'],
            hasValue(errorMsg) && errorMsg !== '' && style['main-wrapper-with-error'],
            restProps.layout === 'small' && style['main-wrapper-center-content']
          )
        }
        >
          <div
            className={clsx(
              style.card,
              restProps.layout === 'small' && style['card-small'],
              restProps.layout === 'medium' && style['card-medium'],
              restProps.layout === 'large' && style['card-large'],
              hasValue(restProps.noPBnoPH) && restProps.noPBnoPH && style['card-no-pb-ph'],
              hasValue(restProps.noPB) && restProps.noPB && style['card-no-pb']
            )}
          >
            <div className={style['card-paper']} />
            {location.pathname.startsWith('/opdracht') && hasValue(sessionContext) && hasValue(cues) && (
              <div
                className={style['cues-layout']}
              >
                <motion.div
                  className={style['cues-container']}
                  initial={{ x: 0 }}
                  animate={{ x: hasValue(isTransitioning) && isTransitioning ? 0 : 72 }}
                  exit={{ x: 0 }}
                  transition={{
                    duration: hasValue(isTransitioning) && isTransitioning ? 0.1 : 0.35,
                    delay: hasValue(isTransitioning) && isTransitioning ? 0 : 0.8
                  }}
                >
                  <p><b>Hints</b></p>
                  <ul>
                    {cues.filter((e: CueState) => e.hasBeenUsed).map((c: CueState, i: number) => {
                      return (
                        <li key={`${c.cue}_${i}`}>
                          <button
                            className={clsx((hasValue(c.active) && c.active) && style.active)}
                            onClick={() => {
                              setCues((old: CueState[] | null) => {
                                if (!hasValue(old)) {
                                  return old
                                }
                                const reset = old.map((e: CueState) => {
                                  return { ...e, active: false }
                                })
                                reset[i] = {
                                  ...reset[i],
                                  hasBeenUsed: true,
                                  active: true
                                }
                                return [...reset]
                              })
                            }}
                          >
                            {i + 1}
                          </button>
                        </li>
                      )
                    })}
                    {hasValue(cues.filter((e: CueState) => !e.hasBeenUsed)[0]) && (
                      <>
                        {cues.filter((e: CueState) => e.hasBeenUsed).length > 0 && (
                          <li>
                            <div className={style['dot-wrapper']}>
                              <div className={style.dot} />
                            </div>
                          </li>
                        )}
                        <li>
                          <button onClick={() => {
                            const newCueIdx = cues.findIndex((e: CueState) => !e.hasBeenUsed)
                            setCues((old: CueState[] | null) => {
                              if (!hasValue(old)) {
                                return old
                              }
                              const reset = old.map((e: CueState) => {
                                return { ...e, active: false }
                              })
                              reset[newCueIdx] = {
                                ...reset[newCueIdx],
                                hasBeenUsed: true,
                                active: true
                              }

                              return [...reset]
                            })
                          }}
                          >
                            ?
                          </button>
                        </li>
                      </>
                    )}
                    {
                      !hasValue(cues.filter((e: CueState) => !e.hasBeenUsed)[0]) && (
                        <>
                          <li>
                            <div className={style['dot-wrapper']}>
                              <div className={style.dot} />
                            </div>
                          </li>
                          <li>
                            <button disabled={isTransitioning} title='Sla over' className={style.skip} onClick={() => setSkip(true)}>
                              <Skip />
                            </button>
                          </li>
                        </>
                      )
                    }
                  </ul>
                </motion.div>
              </div>
            )}

            <div className={style.content}>
              {restProps.children}
            </div>
          </div>

        </main>
      </div>
    </>
  )
}
