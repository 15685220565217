import style from './styles.module.css'
import React from 'react'
import clsx from 'clsx'

interface Props {
  children?: React.ReactNode
  closeFn: () => void
}

export const Modal = (props: Props): JSX.Element => {
  const { closeFn } = props

  const handleUserKeyPress = React.useCallback((event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Escape') {
      closeFn()
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <div className={style['modal-wrapper']}>
      <div className={style.background} onClick={closeFn} />
      <div className={clsx(style.card, style['card-medium'])}>
        <span>{props.children}</span>
      </div>
    </div>
  )
}
