import style from './style.module.css'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { hasValue } from '@root/misc/helpers'
import { CueState, QuestionData } from '@root/services/types'
import { CSSProperties } from 'react'

interface Props {
  data: QuestionData
  isMirrored: boolean
  useAlternativeFont: boolean
  uppercase: boolean
  cues: CueState[]
}

export const CharacterQuestion = ({ data, isMirrored, useAlternativeFont, uppercase, cues }: Props): JSX.Element => {
  const cssStyle: CSSProperties = {
    ...isMirrored ? { transform: 'scaleX(-1)' } : {},
    ...useAlternativeFont ? { fontFamily: 'IndieFlower' } : {},
    ...uppercase ? { textTransform: 'capitalize' } : {}
  }
  const activeCue = cues.filter(e => e.active)[0]
  return (
    <div className={style['cue-audio-wrapper']}>
      <div className={style['audio-wrapper']}>

        {hasValue(activeCue) && activeCue.cue === 'phonologic_stimulus' && (
          <AudioBtn isHint btnSize='large' audioSrcs={[data.audio_url ?? '']} />
        )}
      </div>
      <p className={style.character} style={cssStyle}>{data.value}</p>
    </div>
  )
}
