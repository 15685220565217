import style from './style.module.css'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { hasValue } from '@root/misc/helpers'
import { CueState, QuestionData } from '@root/services/types'
import clsx from 'clsx'

interface Props {
  data: QuestionData[]
  prompt: string
  cues: CueState[]
  correctAnswer: string[]
  isSynonym: boolean
  activeAudio: number | null
  setActiveAudio: (activeAudio: number | null) => void
}

export const AudioQuestion = ({ data, prompt, cues, correctAnswer, isSynonym, activeAudio, setActiveAudio }: Props): JSX.Element => {
  const audio = data.map(d => d.audio_url ?? '')
  const activeCue = cues.filter(e => e.active)[0]

  return (
    <>
      <div className={clsx(style['flex-col'], style['grapheme-audio'])}>

        <div className={style['audio-element']}>
          <AudioBtn btnSize='large' audioSrcs={audio} setActiveFn={setActiveAudio} />
          <p className={style['audio-label']}>{prompt}</p>
        </div>

        {hasValue(activeCue) && activeCue.cue === 'grapheme_stimulus' && data.map(d => (
          <p key={d.grapheme} className={style.grapheme}>{d.grapheme}</p>
        ))}
        {hasValue(activeCue) && activeCue.cue === 'synonym_stimulus' && data.map(d => (
          <p key={d.synonym} className={style.synonym}>Synoniem: {d.synonym}</p>
        ))}
        {hasValue(activeCue) && activeCue.cue === 'written_word_stimulus' && !isSynonym && (
          <p className={style.synonym}>{correctAnswer[0]}</p>
        )}
        <div>
          {
            hasValue(activeCue) && activeCue.cue === 'written_word_stimulus' && isSynonym && data.map((d, i) => {
              return (
                <p key={d.value} className={clsx(style.synonym, activeAudio === i && style.active)}>{d.value}</p>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
