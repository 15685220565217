import { hasValue } from '@root/misc/helpers'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import style from './styles.module.css'
import { ReactComponent as AudioLogo } from '@icons/audio.svg'

interface ButtonProps {
  disabled?: boolean
  children: React.ReactNode
  buttonType?: string
  color?: string
  isSelected?: boolean
  hasCueHighlight?: boolean
}

type Props =
  | ({ as: 'link' } & ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ as: 'button' } & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>)

export function Button (props: Props): JSX.Element {
  const { buttonType = 'primary', color = 'orange', children, isSelected, hasCueHighlight, ...componentProps } = props

  const classes = clsx(
    componentProps.className,
    style.button,
    buttonType === 'primary' && style['button-primary'],
    buttonType === 'primary' && color === 'purple' && style['button-primary-purple'],
    buttonType === 'secondary' && style['button-secondary'],
    buttonType === 'secondary' && color === 'purple' && style['button-secondary-purple'],
    buttonType === 'bordered' && style['button-bordered'],
    buttonType === 'bordered' && color === 'purple' && style['button-bordered-purple'],
    buttonType === 'link' && style['button-link'],
    hasValue(isSelected) && isSelected && style.selected
  )

  if (componentProps.as === 'link') {
    const { as, className, ...linkProps } = componentProps
    return (
      <Link to={linkProps.href ?? ''} className={classes} {...linkProps}>
        {hasValue(hasCueHighlight) && hasCueHighlight && (<div className={style['audio-indicator']}><AudioLogo /></div>)}
        {children}
      </Link>
    )
  } else {
    const { as, className, ...buttonProps } = componentProps
    return (
      <button className={classes} {...buttonProps}>
        {hasValue(hasCueHighlight) && hasCueHighlight && (<div className={style['audio-indicator']}><AudioLogo /></div>)}
        {children}
      </button>
    )
  }
}
