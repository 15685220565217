import { CueState, TrialItem } from '@root/services/types'
import { TextInputAnswer } from '../TextInputAnswer/Component'
import { ImageAnswer } from '../ImageAnswer/Component'
import { MultipleChoiceAnswer } from '../MultipleChoiceAnswer/Component'
import { BooleanAnswer } from '../BooleanAnswer/Component'

interface Props {
  trialItem: TrialItem
  answer: string
  setAnswer: (answer: string) => void
  isBeingChecked: boolean
  cues: CueState[]
  activeAudio: number | null
  setActiveAudio: (activeAudio: number | null) => void
  onSubmitAnswer: () => void
}

export const Answer = ({ trialItem, answer, setAnswer, isBeingChecked, cues, activeAudio, setActiveAudio, onSubmitAnswer }: Props): JSX.Element => {
  switch (trialItem.answer_type) {
    case 'typing_word':
      return <TextInputAnswer isBeingChecked={isBeingChecked} answer={answer} setAnswer={setAnswer} isOneChar={false} onSubmitAnswer={onSubmitAnswer} />
    case 'typing_character':
      return <TextInputAnswer isBeingChecked={isBeingChecked} answer={answer} setAnswer={setAnswer} isOneChar onSubmitAnswer={onSubmitAnswer} />
    case 'image_multiple_choice':
      return <ImageAnswer answerData={trialItem.answer_data.data} answer={answer} setAnswer={setAnswer} isBeingChecked={isBeingChecked} />
    case 'character_lowercase_multiple_choice':
      return <MultipleChoiceAnswer answerData={trialItem.answer_data.data} answer={answer} setAnswer={setAnswer} uppercase={false} isBeingChecked={isBeingChecked} cues={cues} activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
    case 'character_uppercase_multiple_choice':
      return <MultipleChoiceAnswer answerData={trialItem.answer_data.data} answer={answer} setAnswer={setAnswer} uppercase isBeingChecked={isBeingChecked} cues={cues} activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
    case 'boolean':
      return <BooleanAnswer answer={answer} setAnswer={setAnswer} isBeingChecked={isBeingChecked} />
    default:
      return <p>Dit antwoord type wordt alleen ondersteund in de iOS en Android apps</p>
  }
}
