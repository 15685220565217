import clsx from 'clsx'
import React from 'react'
import style from './styles.module.css'

interface ButtonProps {
  disabled?: boolean
  children: React.ReactNode
}

type Props =
  | ({ as: 'link' } & ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ as: 'button' } & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>)

export function HugeButton (props: Props): JSX.Element {
  const { children, ...componentProps } = props

  const classes = clsx(
    props.className,
    style.button
  )

  if (componentProps.as === 'link') {
    const { as, className, ...linkProps } = componentProps
    return (
      <a className={classes} {...linkProps}>
        {children}
      </a>
    )
  } else {
    const { as, className, ...buttonProps } = componentProps
    return (
      <button className={classes} {...buttonProps}>
        {children}
      </button>
    )
  }
}
