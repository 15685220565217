import style from './style.module.css'
import clsx from 'clsx'
import { ReactComponent as ArrowRight } from '@icons/arrow-right.svg'
import { ReactComponent as Refresh } from '@icons/refresh.svg'
import { HugeButton } from '@root/components/atoms/HugeButton/Component'
import { useParams, useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import apiService from '@root/services/apiService'
import { Trial, TrialSession, CueState } from '@root/services/types'
import { useAppContext } from '@root/global/context'
import { Button } from '@root/components/atoms/Button/Component'
import { DotNav } from '@root/components/atoms/DotNav/Component'
import { hasValue } from '@root/misc/helpers'
import { ReactComponent as Check } from '@icons/check.svg'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { ReactComponent as Spinner } from '@icons/spinner.svg'
import { motion } from 'framer-motion'
import { Question } from '@root/components/questions/Question/Component'
import { Answer } from '@root/components/answers/Answer/Component'

// clean shorthand for the React useState setter type, this helps passing setters using a more simple syntax
type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

export const TrialPage = (): JSX.Element => {
  const { trialId } = useParams()
  const { setErrorMsg, cues, setCues, setSessionContext, skip, setSkip, setIsTransitioning } = useAppContext()
  const [error, setError] = useState<boolean>(false)
  const [trial, setTrial] = useState<Trial | null>(null)
  const [session, setSession] = useState<TrialSession | null>(null)
  const [correct, setCorrect] = useState<boolean>(false)
  const [incorrect, setIncorrect] = useState<boolean>(false)
  const [skipped, setSkipped] = useState<boolean>(false)
  const [trialItemIndex, setTrialItemIndex] = useState<number>(0)
  const [answer, setAnswer] = useState<string>('')
  const [sessionSubmitted, setSessionSubmitted] = useState<boolean | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [fakeRestart, setFakeRestart] = useState<boolean>(false)
  const [activeAudio, setActiveAudio] = useState<number | null>(null)

  const navigate = useNavigate()

  const fetchTrial = useCallback(() => {
    setLoading(true)
    setError(false)
    setErrorMsg(null)
    apiService.client.trial(Number(trialId))
      .then((response) => {
        setLoading(false)
        setTrial(response.data)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
        setErrorMsg('Door een probleem kon de oefening niet worden geladen.')
      })
  }, [setErrorMsg, trialId])

  const submitSession = (session: TrialSession): void => {
    if (session != null) {
      setLoading(true)
      setSessionSubmitted(null)
      apiService.client.postSession(session)
        .then(() => {
          setSessionSubmitted(true)
          setLoading(false)
        })
        .catch(() => {
          setSessionSubmitted(false)
          setLoading(false)
        })
    }
  }

  useEffect(() => fetchTrial(), [fetchTrial])
  useEffect(() => {
    if (fakeRestart) setTimeout(() => setFakeRestart(false), 25)
  }, [fakeRestart])

  useEffect(() => {
    if (correct && hasValue(session)) {
      const trialItems = trial?.items
      if (hasValue(trialItems)) {
        setIsTransitioning(true)
        const timer = setTimeout(() => {
          setCorrect(false)
          setTrialItemIndex(trialItemIndex => {
            const newTrialIdx = trialItemIndex + 1
            if (hasValue(trialItems[newTrialIdx])) {
              const cuesState: CueState[] = trialItems[newTrialIdx].cues.map(cue => {
                return {
                  cue,
                  active: false,
                  hasBeenUsed: false
                }
              })
              setCues(cuesState)
            }
            return newTrialIdx
          })
          setFakeRestart(true)
          setIsTransitioning(false)
          setAnswer('')
        }, 2000)
        return () => clearTimeout(timer)
      }
    }
    if (skipped && hasValue(session)) {
      const trialItems = trial?.items
      if (hasValue(trialItems)) {
        setIsTransitioning(true)
        const timer = setTimeout(() => {
          setSkipped(false)
          setTrialItemIndex(trialItemIndex => {
            const newTrialIdx = trialItemIndex + 1
            if (hasValue(trialItems[newTrialIdx])) {
              const cuesState: CueState[] = trialItems[newTrialIdx].cues.map(cue => {
                return {
                  cue,
                  active: false,
                  hasBeenUsed: false
                }
              })
              setCues(cuesState)
            }
            return newTrialIdx
          })
          setFakeRestart(true)
          setIsTransitioning(false)
          setAnswer('')
        }, 200)
        return () => clearTimeout(timer)
      }
    }
  }, [skipped, session, trial])

  useEffect(() => {
    if (incorrect) {
      setIsTransitioning(true)
      const timer = setTimeout(() => {
        setIncorrect(false)
        setAnswer('')
        setIsTransitioning(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [incorrect])

  useEffect(() => {
    setSession((session) => {
      if (session != null && trialItemIndex >= session.trial_session_items.length && session.finished_at == null) {
        const finishedSession = { ...session, finished_at: new Date().toISOString() }
        submitSession(finishedSession)
        return finishedSession
      }
      return session
    })
  }, [trialItemIndex, session])

  useEffect(() => {
    setSessionContext(session)
  }, [session])

  useEffect(() => {
    if (hasValue(skip) && skip) {
      if (session != null) {
        session.trial_session_items[trialItemIndex].answers.push({
          correct: false,
          value: '',
          cues: hasValue(cues) ? cues.filter((c: CueState) => c.hasBeenUsed).map((c: CueState) => c.cue) : []
        })
        setSession({ ...session })
        setSkipped(true)
      }
      setSkip(null)
    }
  }, [skip])

  if (loading) {
    return (<div className={style.loading}><div className='spinner'><Spinner /></div></div>)
  }

  if (error) {
    // error fetching trial
    return getErrorElement(fetchTrial)
  } else if (!hasValue(trial)) {
    // trial is being fetched
    return <></>
  } else if (!hasValue(session)) {
    // show trial intro
    setSessionContext(null)
    const session: TrialSession = {
      client_version: 'Web',
      trial_id: trial.id,
      started_at: new Date().toISOString(),
      finished_at: null,
      trial_session_items: (trial.items ?? []).map((item) => ({ trial_item_id: item.id, answers: [] }))
    }
    return getIntroElement(trial,
      () => {
        setSession(session)
        if (hasValue(trial.items?.[0].cues)) {
          const cuesState: CueState[] = trial.items?.[0].cues.map(cue => {
            return {
              cue,
              active: false,
              hasBeenUsed: false
            }
          }) ?? []
          setCues(cuesState) // expand with state?
        }
      }
    )
  } else if (trialItemIndex >= (trial.items?.length ?? 0) && sessionSubmitted === null) {
    // session is being submitted
    setCues(null)
    return <></>
  } else if (trialItemIndex >= (trial.items?.length ?? 0) && sessionSubmitted === false) {
    // error submitting session
    setCues(null)
    return getSubmitSessionRetryElement(() => submitSession(session))
  } else if (trialItemIndex >= (trial.items?.length ?? 0) && sessionSubmitted === true) {
    // session is successfully submitted
    setCues(null)
    return getOutroElement(trial, session, () => navigate('/overzicht'))
  } else {
    // trial is in progress
    return getAssignmentElement(trial, session, setSession, correct, setCorrect, incorrect, setIncorrect, skipped, answer, setAnswer, trialItemIndex, fakeRestart, cues ?? [], activeAudio, setActiveAudio)
  }
}

function getIntroElement (trial: Trial, onStartSession: () => void): JSX.Element {
  return (
    <>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <h1>{trial.title}</h1>
      </div>
      <div className={style.data}>
        <div className={style['data-no-data']}>
          {hasValue(trial.description) && <div className={clsx(style['flex-row'], style['introduction-text'])}><p>{trial.description}</p></div>}
          <div className={style['flex-row']}>
            <AudioBtn btnSize='medium' audioSrcs={[trial.description_audio]} />
            <p>Instructie: {trial.type_description}</p>
          </div>

        </div>
      </div>
      <div className={style['button-wrapper']}>
        <HugeButton as='button' onClick={onStartSession}>
          <span>Begin met de oefening</span>
          <ArrowRight />
        </HugeButton>
      </div>
    </>
  )
}

function getSubmitSessionRetryElement (onSubmitSession: () => void): JSX.Element {
  return (
    <>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <h1>Uw antwoorden konden niet worden ingestuurd</h1>
      </div>
      <div className={style.data}>
        <div className={style['data-no-data']}>
          <p>Probeer nogmaals om uw antwoorden in te sturen naar uw logopedist.</p>
        </div>
      </div>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <Button type='button' buttonType='secondary' color='purple' as='button' onClick={onSubmitSession}><Refresh /> <span>Opnieuw proberen</span></Button>
      </div>
    </>
  )
}

function getOutroElement (trial: Trial, session: TrialSession, onEndSession: () => void): JSX.Element {
  const questionsDirectly = session.trial_session_items.filter(e => e.answers.length === 1 && e.answers.filter(f => f.correct).length === 1)
  const questionsNoCues = questionsDirectly.filter(e => e.answers.filter(f => f.cues.length === 0).length === 1)
  return (
    <>
      <div className={clsx(style['flex-bar'], style['title-bar'], style['title-bar-success'])}>
        <h1>Bedankt. Oefening voltooid.</h1>
        <div className={style.icon}><Check /></div>
      </div>
      <div className={style.data}>
        <div className={style['data-no-data']}>
          <p>
            U heeft de oefening klaar!
          </p>
          <ul>
            <li>
              U heeft {questionsDirectly.length} van de {trial.items?.length} vragen in één keer goed beantwoord.
            </li>
            <li>
              U heeft {questionsNoCues.length} vragen in één keer goed beantwoord zonder hulp te gebruiken.
            </li>
          </ul>
          <p>De resultaten zijn naar uw logopedist gestuurd.</p>
        </div>
      </div>
      <div className={style['button-wrapper']}>
        <HugeButton as='button' onClick={onEndSession}>
          <span>Terug naar het overzicht</span>
          <ArrowRight />
        </HugeButton>
      </div>
    </>
  )
}

function getErrorElement (onRefreshClick: () => void): JSX.Element {
  return (
    <>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <h1>De oefening kon niet worden opgehaald</h1>
      </div>
      <div className={style.data}>
        <div className={style['data-no-data']}>
          <p>Probeer nogmaals om de oefening op te halen.</p>
        </div>
      </div>
      <div className={clsx(style['flex-bar'], style['title-bar'])}>
        <Button type='button' buttonType='secondary' color='purple' as='button' onClick={onRefreshClick}><Refresh /> <span>Opnieuw proberen</span></Button>
      </div>
    </>
  )
}

function getAssignmentElement (
  trial: Trial,
  session: TrialSession,
  setSession: StateSetter<TrialSession | null>,
  correct: boolean,
  setCorrect: StateSetter<boolean>,
  incorrect: boolean,
  setIncorrect: StateSetter<boolean>,
  skipped: boolean,
  answer: string,
  setAnswer: StateSetter<string>,
  trialItemIndex: number,
  fakeRestart: boolean,
  cues: CueState[],
  activeAudio: number | null,
  setActiveAudio: StateSetter<number | null>
): JSX.Element {
  if (trial.items === undefined) {
    return getErrorElement(() => { })
  }

  const currentItem = trial.items[trialItemIndex]
  const correctAnswers = currentItem.answer_data.correct_value.map((value) => value.toLowerCase())
  const correctRegexes = currentItem.answer_data.correct_regex?.map((value) => value) ?? []

  const onSubmitAnswer = (): void => {
    const answerLower = answer.toLowerCase()
    const answerIsCorrect = correctAnswers.includes(answerLower) || correctRegexes.find((regex) => RegExp(regex).test(answerLower)) !== undefined

    if (answerIsCorrect) {
      setCorrect(true)
    } else {
      setIncorrect(true)
    }

    session.trial_session_items[trialItemIndex].answers.push({
      correct: answerIsCorrect,
      value: answer,
      cues: cues.filter(c => c.hasBeenUsed).map(c => c.cue)
    })
    setSession({ ...session })
  }

  return (
    <>
      <motion.div
        className={clsx(style['flex-bar'], style['title-bar'])}
        initial={{ z: 1 }}
      >
        <div className={style['flex-row']}>
          <AudioBtn btnSize='small' audioSrcs={[trial.description_audio]} />
          <h1>{trial.type_description}</h1>
        </div>
      </motion.div>
      <motion.div
        initial={{
          x: fakeRestart ? '100%' : 0,
          z: 1
        }}
        animate={{
          x: correct || skipped ? ['0%', '-100%'] : fakeRestart ? ['100%', '0%'] : 0
        }}
        transition={{
          delay: correct ? 1.8 : 0,
          duration: 0.35
        }}
      >
        <div className={clsx(style.data, style['question-element'])}>
          <Question trialItem={currentItem} cues={cues} activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
        </div>
        <div className={clsx(style.data, style['answer-element'])}>
          <Answer trialItem={currentItem} answer={answer} setAnswer={setAnswer} isBeingChecked={(correct || incorrect)} cues={cues} activeAudio={activeAudio} setActiveAudio={setActiveAudio} onSubmitAnswer={onSubmitAnswer} />
        </div>
      </motion.div>

      <motion.div
        className={style['dot-nav']}
        initial={{ z: 1 }}
      >
        <div className={clsx(style['dot-nav-confirm'], answer !== '' && style.active)}>
          <HugeButton as='button' onClick={onSubmitAnswer}>
            <span>Bevestig antwoord</span>
            <ArrowRight />
          </HugeButton>
        </div>

        <div className={clsx(style['dot-nav-success'], correct && style.active)}>
          <span />
          <p>Goed! Ga zo door!</p>
        </div>

        <div className={clsx(style['dot-nav-failure'], incorrect && style.active)}>
          <span />
          <p>Niet goed, probeer het nog een keer.</p>
        </div>

        <DotNav count={trial.items.length} progress={trialItemIndex} />
      </motion.div>
    </>
  )
}
