import { LoginPage } from '@pages/non-auth/LoginPage/Component'
import { Layout } from '@pages/templates/Layout/Components'
import '@styles/main.css'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { TrialPage } from './pages/auth/TrialPage/Component'
import { OverviewPage } from './pages/auth/OverviewPage/Component'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout layout='small' noHeader><LoginPage /></Layout>
  }, {
    path: '/overzicht',
    element: <Layout layout='medium' noPBnoPH><OverviewPage /></Layout>
  }, {
    path: '/opdracht/:trialId',
    element: <Layout layout='medium' noPBnoPH navigation='back'><TrialPage /></Layout>
  }
])

function App (): JSX.Element {
  return (
    <RouterProvider router={router} />
  )
}

export default App
