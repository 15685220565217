import style from './style.module.css'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { Button } from '@root/components/atoms/Button/Component'
import { hasValue } from '@root/misc/helpers'
import { AnswerDataData, CueState } from '@root/services/types'
import clsx from 'clsx'
import { CSSProperties } from 'react'

interface Props {
  answerData: AnswerDataData[]
  answer: string
  setAnswer: (answer: string) => void
  uppercase: boolean
  isBeingChecked: boolean
  cues: CueState[]
  activeAudio: number | null
  setActiveAudio: (activeAudio: number | null) => void
}

export const MultipleChoiceAnswer = ({ answerData, answer, setAnswer, uppercase, isBeingChecked, cues, activeAudio, setActiveAudio }: Props): JSX.Element => {
  const activeCue = cues.filter(e => e.active)[0]

  return (
    <div className={clsx(style['answer-grid'], answerData.length === 6 && style['answer-grid-large'])}>
      {hasValue(activeCue) && activeCue.cue === 'phonologic_response' && (
        <AudioBtn isInvisible isHint btnSize='large' audioSrcs={answerData.map(d => d.audio_url ?? '')} setActiveFn={setActiveAudio} />
      )}
      {answerData.map((d, i) => {
        const isSelected = answer === d.value
        const style: CSSProperties = {
          textAlign: 'center',
          ...uppercase ? { textTransform: 'capitalize' } : {}
        }
        return (
          <Button
            key={`${d.value}_${i}`}
            disabled={isBeingChecked}
            color='purple'
            buttonType='bordered'
            as='button'
            type='button'
            onClick={(e) => setAnswer(d.value)}
            isSelected={isSelected}
            style={style}
            hasCueHighlight={hasValue(activeCue) && activeCue.cue === 'phonologic_response' && i === activeAudio}
          >
            {d.value}
          </Button>
        )
      })}
    </div>
  )
}
