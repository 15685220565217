import style from './style.module.css'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { hasValue } from '@root/misc/helpers'
import { CueState, QuestionData } from '@root/services/types'

interface Props {
  data: QuestionData
  cues: CueState[]
  correctAnswer: string[]
}

export const ImageQuestion = ({ data, cues, correctAnswer }: Props): JSX.Element => {
  const activeCue = cues.filter(e => e.active)[0]
  return (
    <div className={style['flex-col']}>
      <div className={style['cue-audio-wrapper']}>
        <div className={style['audio-wrapper']}>
          {hasValue(activeCue) && activeCue.cue === 'phonologic_stimulus' && (
            <AudioBtn isHint btnSize='large' audioSrcs={[data.audio_url ?? '']} />
          )}
        </div>
        <img src={data.image_url ?? ''} className={style.image} alt='' />
      </div>

      {hasValue(activeCue) && activeCue.cue === 'synonym_stimulus' && (
        <p className={style.synonym}>Synoniem: {data.synonym}</p>
      )}

      {hasValue(activeCue) && activeCue.cue === 'grapheme_stimulus' && (
        <p className={style.grapheme}>{data.grapheme}</p>
      )}

      {hasValue(activeCue) && activeCue.cue === 'written_word_stimulus' && (
        <p className={style.synonym}>{correctAnswer[0]}</p>
      )}
    </div>
  )
}
