import clsx from 'clsx'
import style from './styles.module.css'

interface Props {
  count: number
  progress: number
}

export const DotNav = (props: Props): JSX.Element => {
  return (
    <div
      className={
        clsx(
          style['dot-navigation'],
          props.count > 15 && style['dot-navigation-spread']
        )
      }
    >
      {[...Array(props.count)].map((_, i) => {
        if (i < props.progress) {
          return <span key={`bullet-${i}`} className={clsx(style.bullet, style['bullet-done'])} />
        } else if (i === props.progress) {
          return <span key={`bullet-${i}`} className={clsx(style.bullet, style['bullet-active'])} />
        } else {
          return <span key={`bullet-${i}`} className={clsx(style.bullet)} />
        }
      })}
    </div>
  )
}
