import { ReactComponent as Logo } from '@icons/logo.svg'
import { Button } from '@root/components/atoms/Button/Component'
import { TextInput } from '@root/components/atoms/Input/Component'
import apiService from '@root/services/apiService'
import { storeToken } from '@root/services/tokenService'
import { LoginResponse } from '@root/services/types'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './style.module.css'
import { ReactComponent as Spinner } from '@icons/spinner.svg'

export const LoginPage = (): JSX.Element => {
  // const healthCheckHandler = (): void => {
  //   ApiService.health.check()
  //     .then((res: AxiosResponse) => {
  //       console.log(res)
  //     })
  //     .catch((error: AxiosError) => {
  //       console.log(error)
  //     })
  // }
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [hasError, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const submitHandler = (event: React.FormEvent): void => {
    event.preventDefault()

    setError(false)
    setLoading(true)
    apiService.auth.login(username, password)
      .then((res: AxiosResponse<LoginResponse>) => {
        storeToken(res.data.data.token)
        const user = res.data.data.user
        localStorage.setItem('user', JSON.stringify(user))
        navigate('/overzicht')
        setLoading(false)
      })
      .catch((error: AxiosError) => {
        console.error(error)
        setError(true)
        setLoading(false)
      })
  }

  const changeUsernameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setError(false)
    setUsername(e.target.value)
  }

  const changePasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setError(false)
    setPassword(e.target.value)
  }

  return (
    <>
      <Logo />

      <form onSubmit={submitHandler} method='post'>
        <div className={style.form}>
          <h1>Welkom</h1>
          <TextInput required hasError={hasError} name='username' type='text' placeholder='Gebruikersnaam' value={username} onChange={changeUsernameHandler} />
          <TextInput required hasError={hasError} name='password' type='password' placeholder='Inlogcode' autoComplete='off' value={password} onChange={changePasswordHandler} />
          {hasError && (<span className='error-text'>De inloggegevens zijn onjuist. Vraag uw logopedist voor de inloggegevens.</span>)}

        </div>

        <div className={style.toolbar}>
          {
            loading
              ? (<div className='spinner'><Spinner /></div>)
              : (<Button type='submit' buttonType='bordered' as='button'>Inloggen</Button>)
          }
        </div>
        <span className='helper-text'>Vraag uw logopedist voor de inloggegevens</span>

      </form>
    </>
  )
}
