import { CueState, TrialItem } from '@root/services/types'
import { AudioQuestion } from '../AudioQuestion/Component'
import { WordsQuestion } from '../WordsQuestion/Component'
import { CharacterQuestion } from '../CharacterQuestion/Component'
import { ImageQuestion } from '../ImageQuestion/Component'

interface Props {
  trialItem: TrialItem
  cues: CueState[]
  activeAudio: number | null
  setActiveAudio: (activeAudio: number | null) => void
}

export const Question = ({ trialItem, cues, activeAudio, setActiveAudio }: Props): JSX.Element => {
  switch (trialItem.question_type) {
    case 'audio':
      return <AudioQuestion data={trialItem.question_data} prompt='Luister naar het woord' cues={cues} correctAnswer={trialItem.answer_data.correct_value} isSynonym={false} activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
    case 'audio_synonyms':
      return <AudioQuestion data={trialItem.question_data} prompt='Luister naar de woorden' cues={cues} correctAnswer={trialItem.answer_data.correct_value} isSynonym activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
    case 'word_synonyms':
    case 'word':
      return <WordsQuestion data={trialItem.question_data} cues={cues} activeAudio={activeAudio} setActiveAudio={setActiveAudio} />
    case 'character_mirrored':
      return <CharacterQuestion data={trialItem.question_data[0]} isMirrored useAlternativeFont={false} uppercase={false} cues={cues} />
    case 'character_lowercase':
      return <CharacterQuestion data={trialItem.question_data[0]} isMirrored={false} useAlternativeFont={false} uppercase={false} cues={cues} />
    case 'character_uppercase':
      return <CharacterQuestion data={trialItem.question_data[0]} isMirrored={false} useAlternativeFont={false} uppercase cues={cues} />
    case 'character_alternative_font':
      return <CharacterQuestion data={trialItem.question_data[0]} isMirrored={false} useAlternativeFont uppercase={false} cues={cues} />
    case 'image':
      return <ImageQuestion data={trialItem.question_data[0]} cues={cues} correctAnswer={trialItem.answer_data.correct_value} />
    default:
      return <p>Dit vraag type wordt alleen ondersteund in de iOS en Android apps</p>
  }
}
