import style from './style.module.css'
import { AnswerDataData } from '@root/services/types'
import clsx from 'clsx'

interface Props {
  answerData: AnswerDataData[]
  answer: string
  setAnswer: (answer: string) => void
  isBeingChecked: boolean
}

export const ImageAnswer = ({ answerData, answer, setAnswer, isBeingChecked }: Props): JSX.Element => {
  return (
    <div className={clsx(style['image-grid'], answerData.length === 6 && style['image-grid-large'])}>
      {answerData.map((d) => {
        const isSelected = answer === d.value
        return (
          <button disabled={isBeingChecked} className={clsx(style['answer-image'], isSelected && style.active, answerData.length === 2 && style['answer-image-large'])} key={d.value} onClick={(e) => setAnswer(d.value)}>
            <img src={d.image_url ?? undefined} alt='' />
          </button>
        )
      })}
    </div>
  )
}
