import Axios from 'axios'
import { getApiUrl } from '@misc/environments'
import AxiosUtils from '@services/axiosUtils'
import UnprotectedApi from '@services/unprotectedApiClient'
import { axiosAuthRequestInterceptor } from '@services/tokenService'
import * as Types from '@services/types'

// MUST be "cookie" for browser clients in production
// we only set "bearer" in dev because of cross-origin issues
// const TOKEN_SIG_TRANSPORT =
//   process.env.NODE_ENV === 'development' ? 'bearer' : 'cookie'

/// ////////
// Utils //
/// ////////

// interface AuthResponse { data: { data: { access: string, refresh: string } } }

// function onLogin(response: AuthResponse): AuthResponse {
//   tokenService.setStatus(authResponseToTokens(response))
//   return response
// }

/// ////////////////////////
// Setup Axios instances //
/// ////////////////////////

const AuthApi = Axios.create({
  baseURL: getApiUrl(),
  headers: { 'content-type': 'application/json' }
})
AuthApi.interceptors.request.use(axiosAuthRequestInterceptor)
AuthApi.interceptors.request.use(AxiosUtils.csrfHeaderRequestInterceptor)
AuthApi.interceptors.request.use(AxiosUtils.appVersionHeaderRequestInterceptor)
AuthApi.interceptors.response.use((res) => res, AxiosUtils.logErrorInterceptor)
AuthApi.interceptors.response.use((res) => res, AxiosUtils.noAuthInterceptor)

/// ////////////////
// API endpoints //
/// ////////////////

const endpoints = {
  health: {
    check: async () => await UnprotectedApi.get('/healthcheck')
  },
  auth: {
    login: async (username: String, password: String) => await UnprotectedApi.post<Types.LoginResponse>('/auth/identity/callback', {
      user: {
        username,
        access_code: password
      }
    }),
    logout: async () => await AuthApi.delete('api/client/logout')
  },
  client: {
    trials: async () => await AuthApi.get<Types.TrialsResponse>('client/trials?webclient=true'),
    trial: async (id: number) => await AuthApi.get<Types.TrialResponse>(`client/trials/${id}`),
    postSession: async (session: Types.TrialSession) => await AuthApi.post('client/sessions', {
      session
    })
  }
}

// Examples;
// endpoints.me.currentSession
//   .create({
//     email: "superadmin@domain.tld",
//     password: "supercomplexpassword",
//     persistent_session: true,
//   })
//   .then(
//     ({
//       data: {
//         data: { protosession_token },
//       },
//     }) => {
//       endpoints.me.currentSession.completeMfa({
//         token: protosession_token,
//         mfa_code: "500440",
//       });
//     }
//   );

export { endpoints as default, endpoints as UnprotectedApi }
