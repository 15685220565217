import { appContext, useAppValue } from 'global/context'
import App from '@root/App'

function ContextWrapper (): JSX.Element {
  return (
    <appContext.Provider value={useAppValue()}>
      <App />
    </appContext.Provider>
  )
}

export default ContextWrapper
