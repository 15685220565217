import style from './style.module.css'
import { Button } from '@root/components/atoms/Button/Component'

interface Props {
  answer: string
  setAnswer: (answer: string) => void
  isBeingChecked: boolean
}

export const BooleanAnswer = ({ answer, setAnswer, isBeingChecked }: Props): JSX.Element => {
  const values = [
    { name: 'Ja', value: 'true' },
    { name: 'Nee', value: 'false' }
  ]

  return (
    <div className={style['answer-flexbox']}>

      {values.map((d, i) => {
        const isSelected = answer === d.value
        return (
          <Button
            key={`${d.value}_${i}`}
            disabled={isBeingChecked}
            color='purple'
            buttonType='bordered'
            as='button'
            type='button'
            onClick={(e) => setAnswer(d.value)}
            isSelected={isSelected}
          >
            {d.name}
          </Button>
        )
      })}
    </div>
  )
}
