import React from 'react'
import { isClient } from './is-environment'
import { hasValue } from './helpers'

interface Size {
  width: number | undefined
  height: number | undefined
  isDesktop: boolean
}

export default function useWindowSize (): Size {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
    isDesktop: false
  })

  React.useEffect(() => {
    function handleResize (): void {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isDesktop: window.innerWidth >= 768
      })

      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', handleResize)
    if (hasValue(isClient()) && isClient()) {
      handleResize()
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
