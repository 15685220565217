import style from './style.module.css'
import { AudioBtn } from '@root/components/atoms/AudioBtn/Component'
import { hasValue } from '@root/misc/helpers'
import { CueState, QuestionData } from '@root/services/types'
import clsx from 'clsx'
import React from 'react'

interface Props {
  data: QuestionData[]
  cues: CueState[]
  activeAudio: number | null
  setActiveAudio: (activeAudio: number | null) => void
}

export const WordsQuestion = ({ data, cues, activeAudio, setActiveAudio }: Props): JSX.Element => {
  const activeCue = cues.filter(e => e.active)[0]

  return (
    <>
      {hasValue(activeCue) && activeCue.cue === 'phonologic_stimulus' && (
        <AudioBtn isHint btnSize='large' audioSrcs={data.map(d => d.audio_url ?? '')} setActiveFn={setActiveAudio} />
      )}
      {
        data.map((d, i) => {
          if (i !== data.length - 1) {
            return (
              <React.Fragment key={i}>
                <div>
                  <h6 className={clsx(style.word, activeAudio === i && data.length > 1 && style.active)}>{d.value}</h6>

                  {hasValue(activeCue) && activeCue.cue === 'synonym_stimulus' && (
                    <p className={style.synonym}>Synoniem: {d.synonym}</p>
                  )}

                  {hasValue(activeCue) && activeCue.cue === 'grapheme_stimulus' && (
                    <p className={style.grapheme}>{d.grapheme}</p>
                  )}
                </div>
                <div className={style['word-divider']} />
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={i}>
              <div>
                <h6 className={clsx(style.word, activeAudio === i && data.length > 1 && style.active)}>{d.value}</h6>

                {hasValue(activeCue) && activeCue.cue === 'synonym_stimulus' && (
                  <p className={style.synonym}>Synoniem: {d.synonym}</p>
                )}

                {hasValue(activeCue) && activeCue.cue === 'grapheme_stimulus' && (
                  <p className={style.grapheme}>{d.grapheme}</p>
                )}
              </div>
            </React.Fragment>
          )
        })
      }
    </>
  )
}
