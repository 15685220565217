import style from './style.module.css'
import { ReactComponent as CloseIcon } from '@icons/close.svg'
import { useAppContext } from '@root/global/context'

interface Props {
  msg: string
}
export const ErrorMessage = (props: Props): JSX.Element => {
  const { setErrorMsg } = useAppContext()

  const clickHandler = (): void => {
    setErrorMsg(null)
  }

  return (
    <div className={style['error-wrapper']}>
      {props.msg}
      <button className={style.button} onClick={clickHandler}><CloseIcon /></button>

    </div>
  )
}
