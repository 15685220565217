// In case of multiple sites
// import { Site } from '@constants/site';

export function getDevRevalidate (): 15 | null {
  return process.env.NODE_ENV === 'development' ? 15 : null
}
export function getApiUrl (): string {
  return process.env.API_URL ?? process.env.REACT_APP_API_URL ?? ''
}

export function getWebsiteOrigin (): string {
  return (
    process.env.WEBSITE_URL ??
    process.env.REACT_APP_WEBSITE_URL ??
    'https://www.domain.com'
  )
}

export function getWebsiteApiPath (): string {
  return (
    process.env.WEBSITE_API_PATH ??
    process.env.REACT_APP_WEBSITE_API_PATH ??
    '/graphql'
  )
}
