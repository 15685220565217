import { TextInput } from '@root/components/atoms/Input/Component'

interface Props {
  answer: string
  setAnswer: (answer: string) => void
  isOneChar: boolean
  onSubmitAnswer: () => void
  isBeingChecked: boolean
}

export const TextInputAnswer = ({ answer, setAnswer, isOneChar, onSubmitAnswer, isBeingChecked }: Props): JSX.Element => {
  const props: Pick<HTMLInputElement, 'maxLength'> = {
    ...isOneChar ? { maxLength: 1 } : { maxLength: -1 }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onSubmitAnswer()
    }
  }

  return <TextInput {...props} disabled={isBeingChecked} required name='answer' type='text' placeholder='Typ hier het woord' value={answer} onKeyDown={handleKeyPress} onChange={(e) => setAnswer(e.target.value)} />
}
